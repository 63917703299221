<template>
  <st-page :title="$t('DOCUMENT_TEMPLATE.LIST.TITLE')">
    <loader v-if="isLoading" />
    <template #toolbar>
      <document-template-list-toolbar />
    </template>
    <document-template-list-filter>
      <document-template-list-table />
    </document-template-list-filter>
  </st-page>
</template>

<script>
import { mapGetters } from "vuex";
import DocumentTemplateListToolbar from "@/modules/document-template/components/DocumentTemplateListToolbar.vue";
import DocumentTemplateListTable from "@/modules/document-template/components/DocumentTemplateListTable.vue";
import DocumentTemplateListFilter from "@/modules/document-template/components/DocumentTemplateListFilter.vue";

export default {
  name: "DocumentTemplateList",
  components: {
      DocumentTemplateListToolbar,
      DocumentTemplateListTable,
      DocumentTemplateListFilter
  },
  computed: {
        ...mapGetters({
            loading: 'shared/loading',
        }),
        loadingFetch() {
            return this.loading['documentTemplate/get'];
        },
        isLoading() {
            return this.loadingFetch;
        },
    },
};
</script>
